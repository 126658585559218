import React, { useEffect, useState } from 'react';
import { VideoSection, SummarySection } from '../sections';
import { Section } from '../../common';
import Progress from '../../../global/progress/progress';
import RadarChartSection from '../../feedback/sections/radarChartSection';
import Alert from '../../../global/alert/alert';
import PlanTeacherDashboard from '../../organisation/sections/planTeacherDashboard';
import ImageCarousel from '../../../global/imageCarousel/carousel';
import DashboardWebinar from '../../zoom/components/dashboardWebinar';
import { CommunityNewsfeed } from '../../index';
import { useQuery } from 'react-query';
import VideoAlert from '../../../guides/components/videoAlert';
import posthog from 'posthog-js';
import useModal from '../../../hooks/useModal';
import FeatureFlagModal from '../../global/modals/featureFlagModal';
import { LinkStyled, RouterStyled } from '../../../global/link/link.styled';
import { FEATURE_FLAG, VARIANTS } from '../../../service/queryKeys';
import { PATHWAYS_V3 } from '../../../service/queryKeys';
import { getFeatureFlag, getV3Pathways, getVariants } from '../../../service/api';
import Button from '../../../global/button/button';
import ElementSquare from '../../mfgtPage/components/elementSquare';
import MFGT_LOGO from '@app/asset/images/mfgt_icon.png';
import routeBuilder from '../../../service/routeBuilder';
import { ROLE_TEACHER_FREE } from '../../../resources/roles';
import OnboardingDashboard from './onboardingDashboard';
import MeetingsOverview from '../../greatTeachingTeams/components/MeetingsOverview';

const TeacherDashboard = ({
  data,
  isFree,
  hidePlan,
  name,
  email,
  me,
  onboarding = false,
}: {
  data: any;
  isFree: boolean;
  hidePlan?: boolean;
  name: string;
  email: string;
  me?: any;
  onboarding?: boolean;
}) => {
  const [featureFlag, setFeatureFlag] = useState<any>(undefined);
  const [showAlert, setShowAlert] = useState(false);

  const v3Pathways =
    me?.role === ROLE_TEACHER_FREE
      ? useQuery([PATHWAYS_V3], () => getV3Pathways({ archived: +false }), {
          select: (data) => data.data.data,
          staleTime: Infinity,
        })
      : undefined;

  /*
    const [elementEmulated, setElementEmulated] = useState(null);
  */

  const { data: featureFlagName } = useQuery(FEATURE_FLAG, getFeatureFlag, {
    staleTime: Infinity,
    select: (data) => data.data.data,
    placeholderData: [],
  });

  const { data: variantNames } = useQuery(VARIANTS, getVariants, {
    staleTime: Infinity,
    select: (data) => data.data.data,
    placeholderData: [],
  });

  const [modal, toggleModal, setModalProps] = useModal((props: any) => <FeatureFlagModal {...props} />, {});

  const featureFlag1Copy = (
    <p>Did you know you can use our student surveys tool to gather useful feedback from your pupils?</p>
  );

  const featureFlag2Copy = (
    <>
      <p className={'mb-4'}>
        Did you know you can use our student surveys tool to gather useful feedback from your pupils?
      </p>
      <p>
        Almost two thousand teachers like you have already done so, and they tell us that the feedback really helped
        them to feel more confident about their practice and where they could improve. Why not survey your pupils too?
      </p>
    </>
  );

  posthog.setPersonPropertiesForFlags({ id: me.id });

  useEffect(() => {
    const modalSeen = posthog.getFeatureFlag('login-model-flag');
    const surveyCreated = posthog.getFeatureFlag('survey-experiment-completed-flag');

    if (['featureFlag1', 'featureFlag2'].includes(featureFlag)) {
      if (!modalSeen) {
        setModalProps({
          id: me.id,
          text:
            featureFlag === 'featureFlag1' ? (
              featureFlag1Copy
            ) : featureFlag === 'featureFlag2' ? (
              featureFlag2Copy
            ) : (
              <></>
            ),
          preventClose: true,
          preventCloseOnClickOnMask: true,
        });
        setTimeout(toggleModal(true), 1000);
      } else if (modalSeen && !surveyCreated) {
        setShowAlert(true);
      }
    }
  }, [featureFlag]);

  useEffect(() => {
    if (featureFlagName?.length > 0 && variantNames?.length > 0) {
      posthog.onFeatureFlags(function () {
        if (posthog.getFeatureFlag(featureFlagName[0]?.name) === variantNames[0]?.name) {
          setFeatureFlag('featureFlag1');
        } else if (posthog.getFeatureFlag(featureFlagName[0]?.name) === variantNames[1]?.name) {
          setFeatureFlag('featureFlag2');
        }
      });
    }
  }, [featureFlagName, variantNames]);

  return (
    <>
      {onboarding ? (
        <>
          <OnboardingDashboard type={'teacher'} me={me} />
        </>
      ) : (
        <>
          {modal}
          {!isFree && (
            <>
              {/*<div className="flex justify-center items-center w-full relative pb-20 h-14" >
                <PopupButtons formId={'IrcJe5LO'} />
              </div>*/}
              <div className={'block-with-sub-container mb-5'}>
                <MeetingsOverview me={me} />
              </div>
            </>
          )}
          {showAlert ? (
            <VideoAlert
              data={{
                description: (
                  <>
                    {featureFlag === 'featureFlag1' ? (
                      featureFlag1Copy
                    ) : featureFlag === 'featureFlag2' ? (
                      featureFlag2Copy
                    ) : (
                      <></>
                    )}
                    <div className="mt-2">
                      <LinkStyled href={'/student-surveys'}>Click here to get started</LinkStyled>
                    </div>
                  </>
                ),
              }}
              setVideo={setShowAlert}
              showHideForever={false}
              showHide={false}
            />
          ) : (
            <></>
          )}
          {data.zoom && <DashboardWebinar webinarData={data.zoom} name={name} email={email} />}
          <Section
            size={data?.welcome ? null : 'md'}
            className={'field-mb'}
            headline={
              !isFree && (
                <div>
                  <div className={'text-sm'}>
                    {data?.academic_year ? (
                      `${data.academic_year.start_year}-${data.academic_year.end_year}`
                    ) : (
                      <i>No academic year set</i>
                    )}
                  </div>
                </div>
              )
            }
          >
            {!isFree ? (
              data?.welcome ? (
                <div id={'stonly_welcome_video'} className={'w-100 sm:w-10/12 lg:w-8/12 xl:w-6/12 mx-auto'}>
                  <VideoSection video={data.welcome} />
                </div>
              ) : (
                <>
                  <SummarySection
                    chart={<RadarChartSection dashboard />}
                    status={
                      <React.Fragment>
                        {(null == data?.element || null == data?.goal || null == data?.cycle_percentage) && (
                          <div className={'sm-field-mb'}>
                            <Alert>
                              {isFree
                                ? 'Development cycles are a feature of the paid subscription. This is where you would see progress towards your development goals.'
                                : 'Use the development cycles page to record progress on your GTT journey.'}
                            </Alert>
                          </div>
                        )}
                        <div className={'grid grid-cols-1 md:grid-cols-2 gap-4 field-mb'}>
                          <div className={'bg-gray-100 rounded-md border-2 p-2'}>
                            <p className={'font-bold text-muted text-sm mb-1'}>
                              <b>You are working on:</b>
                            </p>
                            <p>{null != data?.element ? data?.element : '—'}</p>
                          </div>
                        </div>
                        <div className={'flex mt-10'}>
                          <Progress progress={data?.cycle_percentage} label={'Development cycle'} />
                        </div>
                      </React.Fragment>
                    }
                  />
                </>
              )
            ) : (
              <></>
            )}
            {!hidePlan && (
              <div className={`${data?.welcome ? 'px-12' : ''}`}>
                <PlanTeacherDashboard isCoordinator={false} />
              </div>
            )}
          </Section>
          {isFree && (
            <>
              <Section size={'md'} className={'field-mb xl:w-2/3'}>
                <div className="bg-light bg-opacity-50 p-6 mb-2 text-center">
                  <p className={'mb-4'}>
                    In this account, we are showing you a worked example of a teacher getting better at Element 4.3 of
                    the Great Teaching Toolkit – Questioning.
                  </p>
                  <div className="shadow-md flex gap-4 my-8 bg-white py-4 px-8 w-min mx-auto">
                    <ElementSquare starterTemplate value={'4.3'} />
                    <h4 className={'my-auto'}>Questioning</h4>
                  </div>
                  <p>
                    Click on the menu items on the left-hand side to see worked examples of each stage of their
                    professional development.
                  </p>
                </div>

                <div className="bg-light bg-opacity-50 mt-4">
                  <p className={'pt-4 pl-4 font-semibold text-lg'}>What is the Great Teaching Toolkit?</p>
                  <div className="mt-4 2xl:flex">
                    <div className={'my-6 ml-6'}>
                      <img
                        src={MFGT_LOGO}
                        className={'mx-auto xl:ml-auto w-40 aspect-square my-4 xl:my-auto'}
                        alt="MFGT_LOGO"
                      />
                    </div>

                    <div className="p-6 mb-2">
                      <p className={'mb-4'}>
                        The Great Teaching Toolkit is built on the{' '}
                        <RouterStyled to={routeBuilder('modelForGreatTeaching').generate()} target={'_blank'}>
                          Model for Great Teaching
                        </RouterStyled>
                        – a curriculum for teacher learning.
                      </p>
                      <p className={'mb-4'}>The Great Teaching Toolkit helps teachers to:</p>
                      <ul className={'list-disc ml-10'}>
                        <li>
                          <strong>set specific goals </strong>
                          for improving their classroom practice, where everyone’s voice is heard.
                        </li>
                        <li>
                          <strong>build understanding </strong>
                          in areas that make the most difference.
                        </li>
                        <li>
                          <strong>develop skills </strong>
                          through modelling, instruction, safe rehearsal and feedback.
                        </li>
                        <li>
                          <strong>embed habits </strong>
                          with development cycles of deliberate practice, feedback and reflection.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="mt-4 bg-light bg-opacity-50 p-6 mb-2 text-center">
                  If you’d like to discuss how you can implement the Great Teaching Toolkit at {me?.organisation}, click
                  on the button below to book a demonstration!
                  <div className="flex gap-4 mt-6">
                    <a
                      className={'mx-auto'}
                      href={'https://meetings.hubspot.com/alex454/speak-to-one-of-the-team-starter-account'}
                      target={'_blank'}
                      rel="noopener noreferrer"
                    >
                      <Button isOutline>Book a demonstration</Button>
                    </a>
                  </div>
                </div>
              </Section>
            </>
          )}
          {/*{isFree && (
        <Section size={null}>
          <div className="p-8">
            <Selector
              id={'test'}
              label={'Emulate'}
              options={ELEMENTS.map((element: any) => {
                return { value: element.elementId, label: `${element.elementId} ${element.element_name}` };
              })}
              value={elementEmulated}
              onChange={(value: any) => setElementEmulated(value)}
            />
            <Button
              disabled={!elementEmulated}
              size={'sm'}
              className={'mt-4'}
              onClick={() => {
                console.log('me.id:', me.id);
                console.log('elementEmulated:', elementEmulated.value);
                localStorage.removeItem('resourcePageFilters');
                localStorage.setItem('resourcePageFilters', elementEmulated.label);
              }}
            >
              Emulate
            </Button>
          </div>
        </Section>
      )}*/}
          <div
            className={`grid ${
              isFree ? 'grid-cols-1' : 'grid-rows-2 lg:grid-cols-2'
            } lg:grid-rows-1 gap-8 mt-8 field-mb`}
          >
            {' '}
            {isFree ? (
              <div className={'my-5 h-84 w-full bg-transparent'}>
                <ImageCarousel />
              </div>
            ) : (
              <CommunityNewsfeed width={'lg'} />
            )}
            {data?.tutorial && !isFree && (
              <Section size={null} headline={'Tutorial'} className={'w-full'}>
                <VideoSection video={data.tutorial} />
              </Section>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default TeacherDashboard;
